.paper-header {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.div-table-wrapper {
	padding: 50px;
}
.title-header {
	margin: 0px 15px;
	text-align: center;
}
.season{
	padding:50px 0px 25px 0px;
	display: flex;
	flex-direction: column;
    justify-content: space-around;
	align-items: center
}
.title-move{
	margin: 20px;
}
.seasonVertical{
	display: flex;
	flex-direction: row;
    justify-content: center;
	
}
.change-season-btn-padding{
	margin: 20px 15px
}
.title-flex{
	display: flex;
    flex-direction: row;
    align-items: center;
}
