.div-table-wrapper {
	padding: 25px;
}
.paper-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.title-header {
	margin: 0px 15px;
	text-align: center;
}
