.remirror-theme {
	width: 100% !important;
	height: 100% !important;
}
.remirror-editor-wrapper {
	width: 100% !important;
}
.remirror-editor-wrapper {
	padding-top: 0px !important;
}
.header-flex {
	display: flex;
	padding: 0px 5px;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
	@media only screen and (max-width: 980px) {
		display: flex;
		padding: 0px 5px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;
	}
}
.header-wrapper {
	display: flex;
	padding: 0px 5px;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
	@media only screen and (max-width: 1400px) {
		display: block;
	}
}
.signature-flex {
	display: flex;
	padding: 0px 5px;
	flex-direction: column;
	justify-content: center;
	width: 200px;
	height: 300px;
}
.remirror-theme .ProseMirror {
	overflow: initial;
}
.heading-flex {
	display: flex !important;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
	font-size: 1.15rem !important;
	height: 1em !important;
	width: 1em !important;
	flex-shrink: 0;
	-webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	box-sizing: inherit;
}

.heading-small-flex {
	display: flex !important;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
	font-size: 0.85rem !important;
	height: 1em !important;
	width: 1em !important;
	flex-shrink: 0;
	-webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	box-sizing: inherit;
}
.variable-popup {
	display: flex;
	flex-direction: column;
}
