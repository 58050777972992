.greeting-app-name-style {
	opacity: 0.6;
}
.greeting-layout {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	line-height: 14px;
	padding: 1px 1px 6px 1px;
	font-size: 11px;
}
.logout-style {
	color: red;
	cursor: pointer;
	text-decoration: none;
}
.hallo-header {
	display: flex;
	flex-direction: row;
}
.hallo-header > *:not(:last-child) {
	display: block;
	margin-right: 5px;
}
