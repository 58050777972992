.paper-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.div-table-wrapper {
	padding: 25px;
}
.title-header {
	margin: 0px 15px;
	text-align: center;
}

.table-action-body {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}
.table-action-body > *:not(:last-child) {
	display: block;
	margin-right: 15px;
}

.create-user-title {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 40px 0px 20px 0px;
}
.container-user-create {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 20px 40px 20px 40px;
	width: 50%;
	height: 100%;
}
.container-centering {
	display: flex;
	justify-content: center;
}
.create-user-button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 20px 0px 20px 0px;
}
.MuiGrid-root {
	display: flex;
	justify-content: center;
	align-items: center;
}

.delete-style {
	cursor: pointer;
	text-decoration: none;
}
