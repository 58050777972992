.process-document-layout{
    margin: 40px;
    display: flex;
    flex-direction: row;
}

.pdfPreview {
    background: #F2F2F2;
    border:     none;
    display:    block;
    height:     100%;
    width:      100%;

    &Container {
        background: #F2F2F2;
        border:     none;
        bottom:     0;
        display:    block;
        height:     100%;
        left:       966px;
        position:   fixed;
        top:        0;
        width:      calc(100% - 966px);
    }
}
.title {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 40px 0px 20px 0px;
}